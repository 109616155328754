<template>
  <div>
    <SidebarFilter ref="sidebar-filter" :filter="filter" :countriesOptions="countries" :tagsOptions="tags" :filterButtonAlignment="filterButtonAlignment" @showResult="onSubmit" @setDefaultFilter="clearFilter" :sliderMaxValue="sliderMaxValue"/>
  </div>
</template>

<script>

import {TRAVEL_TIPS_TO_GET_COUNTRIES_AND_TAGS, TRAVEL_TIPS_WITH_DAYCOUNT_ONLY} from "@/api/graphql/query/TravelTipQuery";
import sharedUtils from '@/utils/sharedUtils'
import 'vue-slider-component/theme/default.css';
import SidebarFilter from "./SidebarFilter.vue";

const SLIDER_VALUE_DEFAULT = [1, 10]

export default {
  name: "TripFilter",
  props: {
    filterButtonAlignment: {
      type: String,
      default: "end"
    },
  },
  components: {
    SidebarFilter
  },

  data: function () {
    return {
      travelTips: [],
      campaigns: [],
      countries: [],
      tags: [],
      countriesSearchString: '',

      filter: {
        countries: [],
        themes: [],
        difficulties: [],
        minLengthOfTravel: SLIDER_VALUE_DEFAULT[0],
        maxLengthOfTravel: this.sliderMaxValue ? this.sliderMaxValue : SLIDER_VALUE_DEFAULT[1],
        transferTypes: [],
        searchString: '',
        tagIds: [],
      },
      travelTipsActual: [],
    }
  },

  mounted: function () {
    if (this.$route.name === "HomePage") this.clearFilter()
      this.$store.state.currentFilter != null ? this.filter = this.$store.state.currentFilter :
      console.log('No filter in store.');
      if(this.$refs.tagg && this.$refs.tagg.offsetHeight) {
      this.isOverflowing = this.$refs.tagg.offsetHeight < this.$refs.tagg.scrollHeight;
    }
  },
  methods: {
    setAllTagsInTravelTips() {
      if (!this.travelTips || this.travelTips.length == 0) return
      let tags = new Set()
      this.travelTips.forEach(travelTip => {
        travelTip.tagCategories.forEach(category => {
          category.tags.forEach(tag => {
            tags.add(tag)
          })
        })
      })
      this.tags = [...tags]
    },
    setAllCountriesInTravelTips() {
      if (!this.travelTips || this.travelTips.length == 0) return
      let allCountries = []
      this.travelTips.forEach (travelTip => {
          travelTip.itineraryDays.forEach(day => {
            day.itineraryItems.forEach(item => {
              if (item.countries) {
              item.countries.forEach(country => {
                if (!allCountries.some(value => value.code === country.code)) {
                  allCountries.push(country)
                }
              }) }
            })
          })
      })
      this.countries = allCountries.sort((a, b) => {
        return sharedUtils.removeAccents(a[this.countryLocalization]).localeCompare(sharedUtils.removeAccents(b[this.countryLocalization]), this.$store.state.preferredLanguage)
      })
    },
    setMaxNumberOfDays(travelTips) {
      let allDaysCount = 0
      travelTips.forEach(tip => allDaysCount = tip.dayCount > allDaysCount ? tip.dayCount : allDaysCount)
      this.filter.maxLengthOfTravel = allDaysCount
      this.setSliderValueToDefault()
      this.$nextTick(() => {
        this.$store.commit('setSliderMaxValue', allDaysCount);
        this.$store.commit('refreshResult');
      })
    },
    clearFilter: function () {
      this.$store.commit('setSliderMaxValue', this.sliderMaxValue);
      this.$store.commit('setDefaultFilter');
      this.filter = this.$store.state.currentFilter
      this.setSliderValueToDefault()
    },
    showClearAll: function () {
      return (this.filter.countries && this.filter.countries.length > 0) || this.filter.themes.length > 0
      || this.filter.difficulties.length > 0 || (this.filter.transferTypes && this.filter.transferTypes.length > 0)
      || this.filter.minLengthOfTravel !== SLIDER_VALUE_DEFAULT[0] || this.filter.maxLengthOfTravel !== this.sliderMaxValue
      || this.filter.searchString;
    },

    onSubmit() {
      this.$store.commit('setCurrentFilter', this.filter);
      if (this.$route.name === 'searchResult') {
        this.$store.commit('refreshResult');
      } else {
        this.$router.push({
          name: 'searchResult',
          params: {previous: 'HomePage'}
        });
      }
    },

    setSliderValueToDefault() {
      this.filter.minLengthOfTravel = SLIDER_VALUE_DEFAULT[0] 
      const maxLengthOfTravel = this.$store.state.sliderMaxValue ? this.$store.state.sliderMaxValue : SLIDER_VALUE_DEFAULT[1]
      this.filter.maxLengthOfTravel = maxLengthOfTravel
      this.$store.commit('setCurrentSlider', maxLengthOfTravel)
      this.$refs['sidebar-filter'].slider.value = [SLIDER_VALUE_DEFAULT[0], maxLengthOfTravel]
    },
  },

  apollo: {
    travelTipsWithDaycountOnly: {
      query: TRAVEL_TIPS_WITH_DAYCOUNT_ONLY,
      variables: {
        filter: null,
        pageSize: null
      },
      result ({ data, loading, networkStatus }) {
        this.setMaxNumberOfDays(data.travelTipsWithDaycountOnly)
      },
      skip() {
        return this.$store.state.sliderMaxValue != undefined
      }
    },
    travelTips: {
      query: TRAVEL_TIPS_TO_GET_COUNTRIES_AND_TAGS,
      variables: {
        filter: null,
        pageSize: null
      }
    }
  },
  computed: {
      countryLocalization() {
        return this.$store.getters.preferredLanguage == "en" ? "nameEnglish" : "nameCzech"
      },
      sliderMaxValue() {
        return this.$store.state.sliderMaxValue
      },
      filteredCountries() {
        if(this.countries) {
          let countries = [...this.countries]
          const searchString = sharedUtils.removeAccents(this.countriesSearchString)
          return countries.filter(c => {
            if (sharedUtils.removeAccents(c[this.countryLocalization]).toUpperCase().includes(searchString.toUpperCase()) || c.nameEnglish.toUpperCase().includes(searchString.toUpperCase())) return true
            return false
          })
        }
        return []
      },
  },

  watch: {
    sliderValue() {
      this.$store.state.currentSliderValue = this.slider.value;
      this.filter.minLengthOfTravel = this.slider.value[0];
      this.filter.maxLengthOfTravel = this.slider.value[1];
    },
    travelTips(nV) {
      this.filter.countries = [...this.filter.countries]
      this.setAllCountriesInTravelTips()
      this.setAllTagsInTravelTips()
    },
    '$store.state.currentFilter' (newValue, oldValue) {
      this.filter = newValue
    },
    // '$store.state.currentSliderValue' (newValue, oldValue) {
    //   this.slider.value = newValue
    // },    
  }
}
</script>